<i18n>
{
  "de": {
    "mapButton": "Karte",
    "satelliteButton": "Satellit",
    "openDetailsLink": "Details anzeigen",
    "buildingsInMapBounds": "{count} Liegenschaft | {count} Liegenschaften",
    "resetMapButton": "Karte zurücksetzen"
  }
}
</i18n>
<template>
  <div class="c-portfolio-map">
    <!-- Map -->
    <div class="map-container">
      <MglMap
        :ref="`${mapMode}-${key}`"
        :key="`${mapMode}-${key}`"
        :accessToken="accessToken"
        :mapStyle.sync="mapStyle"
        :scrollZoom="false"
        :center="mapCenter"
        :zoom="mapZoom"
        :attributionControl="false"
        class="map"
        @click="layerClick"
        @zoomend="updateZoom"
        @moveend="updateCenter"
        @load="onMapLoaded"
      >
        <!-- Adding navigation control -->
        <MglNavigationControl position="top-left" />

        <!-- Old: Point clustering layers -->
        <!-- <MglGeojsonLayer
        :sourceId="buildingsSource.data.id"
        :source="buildingsSource"
        :layerId="clustersLayer.id"
        :layer="clustersLayer"
      /> -->
        <!-- <MglGeojsonLayer
        :sourceId="buildingsSource.data.id"
        :source="buildingsSource"
        :layerId="clusterCountLayer.id"
        :layer="clusterCountLayer"
      /> -->

        <!-- World overlay (for contrast) -->
        <MglGeojsonLayer sourceId="worldSource" :source="worldSource" layerId="worldLayer" :layer="worldLayer" />

        <!-- Building points -->
        <MglGeojsonLayer
          :sourceId="buildingsSource.data.id"
          :source="buildingsSource"
          :layerId="buildingsPointLayer.id"
          :layer="buildingsPointLayer"
          @mousemove="mouseEvent"
          @mouseleave="mouseEvent"
        />

        <MapboxPopup
          :coordinates="popup.coordinates"
          :showed="popup.showed"
          class="map-popup"
          :close-button="false"
          @close="onPopupClose"
        >
          <div>
            <PortfolioListItem
              v-if="activeFeature !== undefined"
              :key="activeFeature.properties.id"
              :building="buildings.find((b) => b.id === activeFeature.properties.id)"
              :portfolio="portfolio"
            />
          </div>
        </MapboxPopup>
      </MglMap>

      <!-- Building count -->
      <div v-if="pointsInMapBounds > 0" class="map-building-count">
        <p>
          {{ $tc('buildingsInMapBounds', pointsInMapBounds, { count: pointsInMapBounds }) }}
        </p>
      </div>

      <!-- Map reset button -->
      <nav
        v-if="!isDefaultMapCenterAndZoom && isBuildingsBoundsZoomUpdated && isBuildingsBoundsCenterUpdated"
        class="round-buttons floating--bottom"
      >
        <button type="button" @click="resetMap">
          {{ $t('resetMapButton') }}
        </button>
      </nav>
    </div>
  </div>
</template>

<script>
import {
  //
  MglMap,
  MglNavigationControl,
  MglGeojsonLayer,
} from 'vue-mapbox'

import MapboxPopup from '@/components/shared/MapboxPopup.vue'
import PortfolioListItem from '@/components/portfolio/PortfolioListItem.vue'

export default {
  components: {
    MglMap,
    MapboxPopup,
    MglNavigationControl,
    PortfolioListItem,
    MglGeojsonLayer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    buildings: {
      type: Array,
      required: true,
    },
    layout: {
      type: String,
      default: 'vertical',
    },
    mapMode: {
      type: String,
      default: 'map',
    },
    mapCenter: {
      type: Object,
      default: () => ({ lng: 8.15, lat: 46.85 }),
    },
    mapZoom: {
      type: Number,
      default: 6.5,
    },
    firstLoad: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      key: 0,
      accessToken: 'pk.eyJ1IjoibWl2dW5lIiwiYSI6ImNsNWNreTd0cTBpZHkza28xeWo1ODJzamEifQ.Srz2_XsFlucH_7qZzPqkLQ',
      // Old custom mapbox style
      // mapStyle: 'mapbox://styles/mivune/cl5clbnp5001a14pvz0v0wugw',
      // swisstopo normal basemap style
      // normalStyle: 'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.basemap_world.vt/style.json?key=QSCGTjvrbWP0n9n3qf98', // key for enrique.ruizdurazo@tend.ch
      // swisstopo light basemap style
      lightStyle:
        'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.lightbasemap_world.vt/style.json?key=QSCGTjvrbWP0n9n3qf98', // key for enrique.ruizdurazo@tend.ch
      // swisstopo satellite basemap style
      satelliteStyle:
        'https://vectortiles.geo.admin.ch/styles/ch.swisstopo.imagerybasemap_world.vt/style.json?key=QSCGTjvrbWP0n9n3qf98', // key for enrique.ruizdurazo@tend.ch
      hoverFeature: undefined,
      activeFeature: undefined,
      popup: {
        coordinates: [8, 48], // This can't be blank! It won't be shown but pick something
        showed: false,
      },
      pointsInMapBounds: 0,
      mapLoaded: false,
      mapCurrentCenter: undefined, // Default placeholder center
      mapCurrentZoom: undefined, // Default placeholder zoom level
      // mapCurrentCenter: { lng: 8.15, lat: 46.85 }, // Default placeholder center
      // mapCurrentZoom: 6.5, // Default placeholder zoom level
      buildingsBoundsZoom: 6.5, // Default placeholder zoom level
      isBuildingsBoundsCenterUpdated: false,
      isBuildingsBoundsZoomUpdated: false,
      isDefaultMapCenterAndZoom: true,
    }
  },

  computed: {
    // Map style
    mapStyle() {
      return this.mapMode === 'satellite' ? this.satelliteStyle : this.lightStyle
    },

    // Map source for a rectangle of the whole world
    worldSource() {
      return {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [-180, 90],
                [180, 90],
                [180, -90],
                [-180, -90],
                [-180, 90],
              ],
            ],
          },
        },
      }
    },

    // Map source for the buildings
    buildingsSource() {
      var features = []
      for (var i = 0; i < this.buildings.length; i++) {
        if (this.buildings[i].latitude && this.buildings[i].longitude) {
          features.push({
            id: this.buildings[i].id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [this.buildings[i].longitude, this.buildings[i].latitude],
            },
            properties: {
              id: this.buildings[i].id,
              status: this.buildings[i].status,
            },
          })
        }
      }
      return {
        type: 'geojson',
        // cluster: true,
        // clusterMaxZoom: 13, // Max zoom to cluster points on
        // clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        data: {
          id: 'buildings',
          type: 'FeatureCollection',
          features: features,
        },
      }
    },

    // Map layer for the buildings
    buildingsPointLayer() {
      return {
        id: 'buildingsPointLayer',
        type: 'circle',
        source: 'buildings',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-radius': 5,
          'circle-stroke-opacity': 0.9,
          'circle-stroke-color': '#ffffff',
          // Assign color based on building role (status)
          'circle-color': [
            'case',
            ['==', ['get', 'status'], 'PLANNER'],
            '#188A02',
            ['==', ['get', 'status'], 'REPORTER'],
            '#15A3C0',
            ['==', ['get', 'status'], 'ARCHIVED'],
            '#777777',
            '#888888',
          ],
          'circle-stroke-width': ['case', ['boolean', ['feature-state', 'hover'], false], 4, 5],
        },
        // before: 'place_country_CH',
      }
    },

    // Map layer for the rectangle of the whole world
    worldLayer() {
      return {
        id: 'worldLayer',
        type: 'fill',
        source: 'worldSource',
        paint: {
          'fill-color': '#000000',
          'fill-opacity': 0.04,
        },
        // before: 'place_country_CH',
      }
    },

    // clusterCountLayer() {
    //   return {
    //     id: 'clusterCountLayer',
    //     type: 'symbol',
    //     source: 'buildings',
    //     filter: ['has', 'point_count'],
    //     layout: {
    //       'text-field': '{point_count_abbreviated}',
    //       // 'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    //       'text-size': 12,
    //     },
    //     paint: {
    //       'text-color': '#ffffff',
    //     },
    //   }
    // },

    // clustersLayer() {
    //   return {
    //     id: 'clustersLayer',
    //     type: 'circle',
    //     source: 'buildings',
    //     filter: ['has', 'point_count'],
    //     paint: {
    //       'circle-color': '#212121',
    //       'circle-stroke-width': 2,
    //       'circle-stroke-opacity': 0.3,
    //       'circle-stroke-color': '#212121',
    //       'circle-radius': [
    //         'step',
    //         ['get', 'point_count'],
    //         20,
    //         Math.ceil(this.buildings.length / 10),
    //         30,
    //         Math.ceil(this.buildings.length / 5) + 1,
    //         40,
    //       ],
    //     },
    //   }
    // },

    // Bounding box of buildings with latitude and longitude
    buildingsBounds() {
      let bounds = {
        minLat: 90,
        maxLat: -90,
        minLon: 180,
        maxLon: -180,
      }
      this.buildings?.forEach((building) => {
        if (building.latitude && building.longitude) {
          bounds.minLat = Math.min(bounds.minLat, building.latitude)
          bounds.maxLat = Math.max(bounds.maxLat, building.latitude)
          bounds.minLon = Math.min(bounds.minLon, building.longitude)
          bounds.maxLon = Math.max(bounds.maxLon, building.longitude)
        }
      })
      return bounds
    },

    //
    buildingsBoundsCenter() {
      return [
        (this.buildingsBounds.minLon + this.buildingsBounds.maxLon) / 2,
        (this.buildingsBounds.minLat + this.buildingsBounds.maxLat) / 2,
      ]
    },

    //
    isDefaultMapCenter() {
      // More sensitive center comparison for zoom levels >= 12
      if (this.mapCurrentZoom >= 12 && this.mapCurrentCenter) {
        return this.isBuildingsBoundsZoomUpdated && this.isBuildingsBoundsCenterUpdated
          ? +this.mapCurrentCenter.lng.toFixed(3) === +this.buildingsBoundsCenter[0].toFixed(3) &&
              +this.mapCurrentCenter.lat.toFixed(3) === +this.buildingsBoundsCenter[1].toFixed(3)
          : true
      }
      return this.isBuildingsBoundsZoomUpdated && this.isBuildingsBoundsCenterUpdated
        ? +this.mapCurrentCenter.lng.toFixed(2) === +this.buildingsBoundsCenter[0].toFixed(2) &&
            +this.mapCurrentCenter.lat.toFixed(2) === +this.buildingsBoundsCenter[1].toFixed(2)
        : true
    },

    //
    isDefaultMapZoom() {
      return this.isBuildingsBoundsZoomUpdated &&
        this.isBuildingsBoundsCenterUpdated &&
        this.mapCurrentZoom &&
        this.mapCurrentCenter
        ? +this.mapCurrentZoom.toFixed(2) === +this.buildingsBoundsZoom.toFixed(2)
        : true
    },
  },

  watch: {
    buildings() {
      if (this.mapLoaded) {
        if (this.buildings.length) {
          this.pointsInMapBounds = this.getPointCountInMapBounds(this.$refs[`${this.mapMode}-${this.key}`].map)
        }
        if (this.buildingsBounds.minLat !== 90) {
          this.fitBounds()
        }
      }
    },

    buildingsBounds() {
      if (this.buildingsBounds.minLat !== 90) {
        this.fitBounds()
      }
    },

    mapCurrentCenter() {
      // If the default zoom has never been updated, then the map is still in the default state
      if (!this.isBuildingsBoundsZoomUpdated || !this.isBuildingsBoundsCenterUpdated) {
        this.isDefaultMapCenterAndZoom = true
      }
      this.isDefaultMapCenterAndZoom = this.isDefaultMapCenter && this.isDefaultMapZoom
    },

    mapCurrentZoom() {
      // If the default zoom has never been updated, then the map is still in the default state
      if (!this.isBuildingsBoundsZoomUpdated || !this.isBuildingsBoundsCenterUpdated) {
        this.isDefaultMapCenterAndZoom = true
      }
      this.isDefaultMapCenterAndZoom = this.isDefaultMapZoom && this.isDefaultMapCenter
    },
  },

  activated() {
    // Solves the problem of the map not being shown in the correct size when navigating back to the page
    this.key += 1 // Updating the key forces the component to be re-rendered
    this.isBuildingsBoundsZoomUpdated = false
    this.isBuildingsBoundsCenterUpdated = false
    this.isDefaultMapCenterAndZoom = true
    // If the map is not in the default state, the center and zoom are updated
    // if (!((this.mapCenter.lng === 8.15) & (this.mapCenter.lat === 46.85) && this.mapZoom === 6.5)) {
    if (!this.firstLoad) {
      this.isBuildingsBoundsZoomUpdated = true
      this.isBuildingsBoundsCenterUpdated = true
      this.isDefaultMapCenterAndZoom = false
    }
  },

  // Emit the current center and zoom of the map when component is deactivated
  deactivated() {
    this.$emit('onMapCenterUpdated', this.$refs[`${this.mapMode}-${this.key}`].map.getCenter())
    this.$emit('onMapZoomUpdated', this.$refs[`${this.mapMode}-${this.key}`].map.getZoom())
  },

  methods: {
    // Get the number of points currently in the map bounds
    getPointCountInMapBounds(map) {
      const bounds = map.getBounds()
      let pointsInMapBounds = 0
      this.buildings.forEach((building) => {
        if (building.latitude && building.longitude && bounds.contains([building.longitude, building.latitude])) {
          pointsInMapBounds += 1
        }
      })
      return pointsInMapBounds
    },

    //
    onMapLoaded(event) {
      this.mapLoaded = true
      this.pointsInMapBounds = this.getPointCountInMapBounds(event.map)

      // Set the map to the bounds of the buildings
      if (this.buildingsBounds.minLat !== 90 && this.firstLoad) {
        this.fitBounds()
      }
    },

    // Fit the map to the bounds of the buildings
    fitBounds() {
      if (this.mapLoaded) {
        this.$refs[`${this.mapMode}-${this.key}`].map
          .fitBounds(
            [
              [this.buildingsBounds.minLon, this.buildingsBounds.minLat],
              [this.buildingsBounds.maxLon, this.buildingsBounds.maxLat],
            ],
            { padding: 100, maxZoom: 16, duration: 1000 }
          )
          .on('move', () => {
            if (this.isBuildingsBoundsZoomUpdated) {
              this.isDefaultMapCenterAndZoom = true
            }
          })
          .on('zoom', () => {
            if (this.isBuildingsBoundsCenterUpdated) {
              this.isDefaultMapCenterAndZoom = true
            }
          })
          .once('zoomend', () => {
            this.buildingsBoundsZoom = this.$refs[`${this.mapMode}-${this.key}`].map.getZoom()
            this.pointsInMapBounds = this.getPointCountInMapBounds(this.$refs[`${this.mapMode}-${this.key}`].map)
            this.isBuildingsBoundsZoomUpdated = true
          })
          .once('moveend', () => {
            this.pointsInMapBounds = this.getPointCountInMapBounds(this.$refs[`${this.mapMode}-${this.key}`].map)
            this.isBuildingsBoundsCenterUpdated = true
          })
      }
    },

    //
    updateZoom(event) {
      if (this.isBuildingsBoundsZoomUpdated) {
        this.mapCurrentZoom = event.map.getZoom()
        this.pointsInMapBounds = this.getPointCountInMapBounds(event.map)
      }
    },

    //
    updateCenter(event) {
      if (this.isBuildingsBoundsCenterUpdated) {
        this.mapCurrentCenter = event.map.getCenter()
        this.pointsInMapBounds = this.getPointCountInMapBounds(event.map)
      }
    },

    //
    onPopupClose() {
      this.popup.showed = false
      this.activeFeature = undefined
    },

    //
    layerClick(event) {
      let features = event.map.queryRenderedFeatures(event.mapboxEvent.point, {
        layers: ['buildingsPointLayer'],
      })
      if (features.length > 0) {
        this.activeFeature = features[0]
        this.$router.push({
          name: 'buildingDetails',
          params: { portfolio_id: this.portfolio.id, building_id: features[0].id },
        })
      } else {
        event.map.getCanvas().style.cursor = ''
      }
    },

    //
    mouseEvent(event) {
      let features = event.map.queryRenderedFeatures(event.mapboxEvent.point, {
        layers: ['buildingsPointLayer'],
      })
      if (this.hoverFeature !== undefined) {
        event.map.setFeatureState({ source: 'buildings', id: this.hoverFeature.id }, { hover: false })
        this.popup.showed = false
        event.map.getCanvas().style.cursor = ''
      }
      if (features.length > 0) {
        event.map.getCanvas().style.cursor = 'pointer'
        this.hoverFeature = features[0]
        event.map.setFeatureState({ source: 'buildings', id: this.hoverFeature.id }, { hover: true })
        this.activeFeature = features[0]
        this.popup.coordinates = this.activeFeature.geometry.coordinates
        this.popup.showed = true
      } else {
        event.map.getCanvas().style.cursor = ''
        this.hoverFeature = undefined
      }
    },

    //
    resetMap() {
      this.isBuildingsBoundsCenterUpdated = false
      this.isBuildingsBoundsZoomUpdated = false
      this.isDefaultMapCenterAndZoom = true
      this.fitBounds()
    },
  },
}
</script>

<style lang="scss">
.c-portfolio-map {
  position: relative;
  height: 100%;

  & .map-container {
    position: relative;
    height: calc(100% - 65px);
  }

  & .map-popup {
    min-width: 400px;
  }

  & .map-building-count {
    position: absolute;
    top: 10px;
    left: 50%;
    background-color: #ffffff;
    padding: 6px 12px;
    border-radius: 24px;
    z-index: 5;
    transform: translateX(-50%);
  }

  & nav.round-buttons.floating--bottom {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    transition: opacity 0.3s ease-in-out;

    @media screen and (max-width: 1280px) {
      bottom: 10px;
    }
  }

  & .mapboxgl-popup-content {
    padding: 4px 0 2px;
    border-radius: 6px;
    min-width: 250px;
    max-width: 290px;
    font-family: var(--font-secondary);

    & > div > * {
      border: none;
    }
  }

  & .mapboxgl-popup-anchor-top-right {
    .mapboxgl-popup-content {
      border-top-right-radius: 0;
    }
  }

  & .mapboxgl-popup-anchor-top-left {
    .mapboxgl-popup-content {
      border-top-left-radius: 0;
    }
  }

  & .mapboxgl-popup-anchor-bottom-right {
    .mapboxgl-popup-content {
      border-bottom-right-radius: 0;
    }
  }

  & .mapboxgl-popup-anchor-bottom-left {
    .mapboxgl-popup-content {
      border-bottom-left-radius: 0;
    }
  }
}
</style>
