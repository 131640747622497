<i18n>
{
  "de": {
    "mapButton": "Karte",
    "satelliteButton": "Satellit"
  }
}
</i18n>

<template>
  <div class="c-portfolio-map-container">
    <!-- Map / Sattelite toggle -->
    <div class="buttons-container">
      <nav class="round-buttons">
        <button type="button" :class="{ selected: mapMode === 'map' }" @click="setMapMode('map')">
          <span>{{ $t('mapButton') }}</span>
        </button>
        <button type="button" :class="{ selected: mapMode === 'satellite' }" @click="setMapMode('satellite')">
          <span>{{ $t('satelliteButton') }}</span>
        </button>
      </nav>
    </div>

    <!-- Map -->
    <keep-alive>
      <PortfolioMap
        v-if="mapMode === 'map'"
        :buildings="buildings"
        :portfolio="portfolio"
        map-mode="map"
        :map-center="mapCenter"
        :map-zoom="mapZoom"
        :first-load="firstLoad"
        @onMapCenterUpdated="onMapCenterUpdated"
        @onMapZoomUpdated="onMapZoomUpdated"
      />
    </keep-alive>
    <keep-alive>
      <PortfolioMap
        v-if="mapMode === 'satellite'"
        :buildings="buildings"
        :portfolio="portfolio"
        map-mode="satellite"
        :map-center="mapCenter"
        :map-zoom="mapZoom"
        :first-load="firstLoad"
        @onMapCenterUpdated="onMapCenterUpdated"
        @onMapZoomUpdated="onMapZoomUpdated"
      />
    </keep-alive>
  </div>
</template>

<script>
import PortfolioMap from '@/components/portfolio/PortfolioMap.vue'

export default {
  components: {
    PortfolioMap,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    buildings: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      mapCenter: { lng: 8.15, lat: 46.85 },
      mapZoom: 6.5,
      firstLoad: true,
    }
  },

  computed: {
    mapMode() {
      return this.$store.state.ui.portfolioMapMode
    },
  },

  created() {
    // Initialize map vs satellite
    const mapMode = localStorage.getItem('portfolioMapMode')
    if (mapMode) {
      this.$store.dispatch('ui/setPortfolioMapMode', mapMode)
    }
  },

  methods: {
    //
    setMapMode(mode) {
      this.$store.dispatch('ui/setPortfolioMapMode', mode)
      localStorage.setItem('portfolioMapMode', mode)
    },

    //
    onMapCenterUpdated(center) {
      this.$nextTick(() => {
        this.mapCenter = center
        this.firstLoad = false
      })
      this.firstLoad = false
    },

    //
    onMapZoomUpdated(zoom) {
      this.$nextTick(() => {
        this.mapZoom = zoom
        this.firstLoad = false
      })
      this.firstLoad = false
    },
  },
}
</script>

<style lang="scss">
.c-portfolio-map-container {
  position: relative;
  max-height: 540px;

  & .map-popup {
    min-width: 400px;
  }

  & .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 12px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
  }
}
</style>
