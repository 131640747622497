<i18n>
{
  "de": {
    "performanceButton": "Performance",
    "overviewButtons": {
      "overviewButton": "Übersicht",
      "buildingsButton": "Liegenschaften",
      "heatingButton": "Wärme",
      "electricityButton": "Strom",
      "buildingComponentsButton": "Bauteile",
      "availabilityButton": "Machbarkeit"
    },
    "energyButtons": {
      "energyButton": "Energie",
      "eeButton": "Endenergie",
      "peButton": "Primärenergie (PE)",
      "penrButton": "PE Fossil",
      "perButton": "PE Erneuerbar"
    },
    "emissionsButtons": {
      "emissionsButton": "Emissionen",
      "s12eButton": "Scope 1-2",
      "s123eButton": "Scope 1-3",
      "s1eButton": "Scope 1",
      "s2eButton": "Scope 2",
      "s3eButton": "Scope 3"
    },
    "dataButtons": {
      "dataButton": "Daten",
      "dataQualityButton": "Datenqualität"
    },
    "countButton": "Anzahl",
    "byAreaButton": "Fläche",
    "totalLabel": "Total",
    "buildingTypeTitle": "Übersicht",
    "performanceTitle": "Performance",
    "yearTitle": "Baujahr",
    "areaTitle": "Fläche",
    "usageTitle": "Hauptnutzung",
    "heatingTitle": "Heizung",
    "byUsage": "Nach Verwendungszweck",
    "byCarrier": "Nach Energieträger",
    "heatTitle": "Wärme",
    "labelTitle": "Effizienzklassen",
    "rhDemandHistogramTitle": "Nutzenergie Raumwärme",
    "heatEndEnergyHistogramTitle": "Endenergiebedarf Heizung inkl. WW",
    "rhCalibrationHistogramTitle": "Kalibrierung Raumwärme",
    "electricityTitle": "Strom",
    "elDemandHistogramTitle": "Strombedarf Total",
    "elGeneralHistogramTitle": "Allgemeinstrom",
    "elTenantHistogramTitle": "Mieterstrom",
    "elProductionHistogramTitle": "Stromproduktion",
    "availabilityTitle": "Machbarkeit",
    "ewsPieTitle": "Erdwärmesonden",
    "gwPieTitle": "Wärmenutzung Grundwasser",
    "districtPieTitle": "Wärmenetze",
    "componentsTitle": "Bauteile",
    "heatingYearTitle": "Baujahr Heizung",
    "wallsYearTitle": "Baujahr Fassade",
    "windowsYearTitle": "Baujahr Fenster",
    "roofYearTitle": "Baujahr Dach",
    "dataQualityTitle": "Daten",
    "endEnergyTitle": "Endenergiebedarf",
    "emissionsTitle": "Scope 1-2 Emissionen",
    "energyAreaTitle": "Energiebezugsfläche"
  }
}
</i18n>

<template>
  <div class="c-portfolio-kpis">
    <!-- KPI mode toggle -->
    <div class="buttons-container buttons-container--overflow">
      <nav class="round-buttons">
        <button type="button" :class="{ selected: kpiMode === 'performance' }" @click="setKpiMode('performance')">
          <span>{{ $t('performanceButton') }}</span>
        </button>
        <button type="button" :class="{ selected: kpiMode === 'overview' }" @click="setKpiMode('overview')">
          <span>{{ $t('overviewButtons.overviewButton') }}</span>
        </button>
        <button type="button" :class="{ selected: kpiMode === 'energy' }" @click="setKpiMode('energy')">
          <span>{{ $t('energyButtons.energyButton') }}</span>
        </button>
        <button type="button" :class="{ selected: kpiMode === 'emissions' }" @click="setKpiMode('emissions')">
          <span>{{ $t('emissionsButtons.emissionsButton') }}</span>
        </button>
        <button type="button" :class="{ selected: kpiMode === 'data' }" @click="setKpiMode('data')">
          <span>{{ $t('dataButtons.dataButton') }}</span>
        </button>
      </nav>
    </div>

    <!-- KPI tab toggles -->
    <div v-if="kpiMode !== 'performance'" class="buttons-container">
      <!-- Menu buttons: Overview, Energy, Emissions -->
      <div v-if="kpiMode !== 'data'" class="buttons-container__menu">
        <!-- Overview buttons -->
        <Menu v-if="kpiMode === 'overview'" left :buttonText="currentKpiTabLabel">
          <template #options>
            <MenuItem
              :id="`overview-0`"
              :value="0"
              :label="$t('overviewButtons.buildingsButton')"
              type="label"
              @click="setSelectedKpiTabIndex('overview', 0)"
            />
            <MenuItem
              :id="`overview-1`"
              :value="1"
              :label="$t('overviewButtons.heatingButton')"
              type="label"
              @click="setSelectedKpiTabIndex('overview', 1)"
            />
            <MenuItem
              :id="`overview-2`"
              :value="2"
              :label="$t('overviewButtons.electricityButton')"
              type="label"
              @click="setSelectedKpiTabIndex('overview', 2)"
            />
            <MenuItem
              :id="`overview-3`"
              :value="3"
              :label="$t('overviewButtons.buildingComponentsButton')"
              type="label"
              @click="setSelectedKpiTabIndex('overview', 3)"
            />
            <MenuItem
              :id="`overview-4`"
              :value="4"
              :label="$t('overviewButtons.availabilityButton')"
              type="label"
              @click="setSelectedKpiTabIndex('overview', 4)"
            />
          </template>
        </Menu>

        <!-- Energy buttons -->
        <Menu v-if="kpiMode === 'energy'" left :buttonText="currentKpiTabLabel">
          <template #options>
            <MenuItem
              :id="`energy-0`"
              :value="0"
              :label="$t('energyButtons.eeButton')"
              type="label"
              @click="setSelectedKpiTabIndex('energy', 0)"
            />
            <MenuItem
              :id="`energy-1`"
              :value="1"
              :label="$t('energyButtons.peButton')"
              type="label"
              @click="setSelectedKpiTabIndex('energy', 1)"
            />
            <MenuItem
              :id="`energy-2`"
              :value="2"
              :label="$t('energyButtons.penrButton')"
              type="label"
              @click="setSelectedKpiTabIndex('energy', 2)"
            />
            <MenuItem
              :id="`energy-3`"
              :value="3"
              :label="$t('energyButtons.perButton')"
              type="label"
              @click="setSelectedKpiTabIndex('energy', 3)"
            />
          </template>
        </Menu>

        <!-- Emissions buttons -->
        <Menu v-if="kpiMode === 'emissions'" left :buttonText="currentKpiTabLabel">
          <template #options>
            <MenuItem
              :id="`emissions-0`"
              :value="0"
              :label="$t('emissionsButtons.s12eButton')"
              type="label"
              @click="setSelectedKpiTabIndex('emissions', 0)"
            />
            <MenuItem
              :id="`emissions-1`"
              :value="1"
              :label="$t('emissionsButtons.s123eButton')"
              type="label"
              @click="setSelectedKpiTabIndex('emissions', 1)"
            />
            <MenuItem
              :id="`emissions-2`"
              :value="2"
              :label="$t('emissionsButtons.s1eButton')"
              type="label"
              @click="setSelectedKpiTabIndex('emissions', 2)"
            />
            <MenuItem
              :id="`emissions-3`"
              :value="3"
              :label="$t('emissionsButtons.s2eButton')"
              type="label"
              @click="setSelectedKpiTabIndex('emissions', 3)"
            />
            <MenuItem
              :id="`emissions-4`"
              :value="4"
              :label="$t('emissionsButtons.s3eButton')"
              type="label"
              @click="setSelectedKpiTabIndex('emissions', 4)"
            />
          </template>
        </Menu>
      </div>

      <!-- Round buttons: Overview, Energy, Emissions, Data -->
      <div v-if="kpiMode !== 'data'" class="buttons-container__round-buttons">
        <!-- Overview buttons -->
        <nav v-if="kpiMode === 'overview'" class="round-buttons">
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('overview') === 0 }"
            @click="setSelectedKpiTabIndex('overview', 0)"
          >
            <span>{{ $t('overviewButtons.buildingsButton') }}</span>
          </button>
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('overview') === 1 }"
            @click="setSelectedKpiTabIndex('overview', 1)"
          >
            <span>{{ $t('overviewButtons.heatingButton') }}</span>
          </button>
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('overview') === 2 }"
            @click="setSelectedKpiTabIndex('overview', 2)"
          >
            <span>{{ $t('overviewButtons.electricityButton') }}</span>
          </button>
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('overview') === 3 }"
            @click="setSelectedKpiTabIndex('overview', 3)"
          >
            <span>{{ $t('overviewButtons.buildingComponentsButton') }}</span>
          </button>
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('overview') === 4 }"
            @click="setSelectedKpiTabIndex('overview', 4)"
          >
            <span>{{ $t('overviewButtons.availabilityButton') }}</span>
          </button>
        </nav>

        <!-- Energy buttons -->
        <nav v-if="kpiMode === 'energy'" class="round-buttons">
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('energy') === 0 }"
            @click="setSelectedKpiTabIndex('energy', 0)"
          >
            <span>{{ $t('energyButtons.eeButton') }}</span>
          </button>
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('energy') === 1 }"
            @click="setSelectedKpiTabIndex('energy', 1)"
          >
            <span>{{ $t('energyButtons.peButton') }}</span>
          </button>
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('energy') === 2 }"
            @click="setSelectedKpiTabIndex('energy', 2)"
          >
            <span>{{ $t('energyButtons.penrButton') }}</span>
          </button>
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('energy') === 3 }"
            @click="setSelectedKpiTabIndex('energy', 3)"
          >
            <span>{{ $t('energyButtons.perButton') }}</span>
          </button>
        </nav>

        <!-- Emissions buttons -->
        <nav v-if="kpiMode === 'emissions'" class="round-buttons">
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('emissions') === 0 }"
            @click="setSelectedKpiTabIndex('emissions', 0)"
          >
            <span>{{ $t('emissionsButtons.s12eButton') }}</span>
          </button>
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('emissions') === 1 }"
            @click="setSelectedKpiTabIndex('emissions', 1)"
          >
            <span>{{ $t('emissionsButtons.s123eButton') }}</span>
          </button>
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('emissions') === 2 }"
            @click="setSelectedKpiTabIndex('emissions', 2)"
          >
            <span>{{ $t('emissionsButtons.s1eButton') }}</span>
          </button>
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('emissions') === 3 }"
            @click="setSelectedKpiTabIndex('emissions', 3)"
          >
            <span>{{ $t('emissionsButtons.s2eButton') }}</span>
          </button>
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('emissions') === 4 }"
            @click="setSelectedKpiTabIndex('emissions', 4)"
          >
            <span>{{ $t('emissionsButtons.s3eButton') }}</span>
          </button>
        </nav>
      </div>

      <!-- Data buttons -->
      <div>
        <nav v-if="kpiMode === 'data'" class="round-buttons">
          <button
            type="button"
            :class="{ selected: getPortfolioKpiTabIndex('data') === 0 }"
            @click="setSelectedKpiTabIndex('data', 0)"
          >
            <span>{{ $t('dataButtons.dataQualityButton') }}</span>
          </button>
        </nav>
      </div>

      <!-- Count / Area toggle -->
      <nav class="toggle-buttons">
        <button type="button" :class="{ selected: kpiDisplayMode === 'count' }" @click="setKpiDisplayMode('count')">
          <span>{{ $t('countButton') }}</span>
        </button>
        <button type="button" :class="{ selected: kpiDisplayMode === 'area' }" @click="setKpiDisplayMode('area')">
          <span>{{ $t('byAreaButton') }}</span>
        </button>
      </nav>
    </div>

    <!-- KPI charts -->
    <div class="kpis-container">
      <!-- Performance -->
      <div v-if="kpiMode === 'performance'" :key="`performanceTitle`" class="tab tab--performance">
        <!-- Bubble chart -->
        <div>
          <BubbleChartWrapper
            key="buildingbubble"
            :chart-data="chartData"
            class="bubble-chart"
            @click="onBubbleClick"
          />

          <div v-if="totalEnergyArea" class="kpis">
            <KpiBox
              :label="$t('areaTitle')"
              :value="formatNumber(totalEnergyArea)"
              valueUnit="m²"
              :relativeValue="$t('energyAreaTitle')"
            />
            <KpiBox
              :label="$t('endEnergyTitle')"
              :value="formatNumber(totalKpi('ee') / 1000, 1)"
              valueUnit="MWh"
              :relativeValue="formatNumber(totalKpi('ee') / totalEnergyArea, 1)"
              relativeValueUnit="kWh/m²"
            />
            <KpiBox
              :label="$t('emissionsTitle')"
              :value="formatNumber(totalKpi('s12e') / 1000, 1)"
              valueUnit="t CO₂"
              :relativeValue="formatNumber(totalKpi('s12e') / totalEnergyArea, 1)"
              relativeValueUnit="kg CO₂/m²"
            />
          </div>
        </div>
      </div>

      <!-- Overview -->
      <template v-if="portfolio.enabled_features.KPI && kpiMode === 'overview'">
        <!-- Buildings -->
        <div v-if="selectedKpiTabIndex === 0" :key="`buildingTypeTitle`" class="tab">
          <div>
            <h3>{{ $t('heatingTitle') }}</h3>
            <TagPieChart
              key="heatingpiechart"
              :by-area="byArea"
              :group-by-key="'heating'"
              :portfolio="portfolio"
              :chart-data="chartData"
              class="pie-chart"
            />
          </div>
          <div>
            <h3>{{ $t('usageTitle') }}</h3>
            <TagPieChart
              key="usagepiechart"
              :by-area="byArea"
              :group-by-key="'usage'"
              :portfolio="portfolio"
              :chart-data="chartData"
              class="pie-chart"
            />
          </div>
          <div>
            <h3>{{ $t('yearTitle') }}</h3>
            <HistogramChart
              key="buildingyearhistogram"
              :by-area="byArea"
              :group-by-key="'building_year'"
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
          <div>
            <h3>{{ $t('areaTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalEnergyArea) }} m²)</h3>
            <HistogramChart
              key="areahistogram"
              :by-area="byArea"
              :group-by-key="'energy_area'"
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
        </div>

        <!-- Heating -->
        <div v-if="selectedKpiTabIndex === 1" :key="`heatingTitle`" class="tab">
          <div>
            <h3>{{ $t('labelTitle') }}</h3>
            <HistogramChart
              key="rhlabelhistogram"
              :by-area="byArea"
              :group-by-key="'rh_ratio'"
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
          <div>
            <h3>
              {{ $t('rhDemandHistogramTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalRh / 1000, 0) }} MWh)
            </h3>
            <HistogramChart
              key="rhhistogram"
              :by-area="byArea"
              :group-by-key="'rh_demand'"
              area-specific
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
          <div>
            <h3>
              {{ $t('heatEndEnergyHistogramTitle') }} ({{ $t('totalLabel') }}:
              {{ formatNumber(totalHeatEndEnergy / 1000, 0) }} MWh)
            </h3>
            <HistogramChart
              key="heat_end_energy_histogram"
              :by-area="byArea"
              :group-by-key="'heat_end_energy'"
              area-specific
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
          <div>
            <h3>{{ $t('rhCalibrationHistogramTitle') }}</h3>
            <HistogramChart
              key="rhcalibhistogram"
              :by-area="byArea"
              :group-by-key="'rh_calibration'"
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
        </div>

        <!-- Electricity -->
        <div v-if="selectedKpiTabIndex === 2" :key="`electricityTitle`" class="tab">
          <div>
            <h3>
              {{ $t('elDemandHistogramTitle') }} ({{ $t('totalLabel') }}: {{ formatNumber(totalEl / 1000, 0) }} MWh)
            </h3>
            <HistogramChart
              key="elhistogram"
              :by-area="byArea"
              :group-by-key="'el_demand'"
              area-specific
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
          <div>
            <h3>
              {{ $t('elTenantHistogramTitle') }} ({{ $t('totalLabel') }}:
              {{ formatNumber(totalElTenant / 1000, 0) }} MWh)
            </h3>
            <HistogramChart
              key="eltenanthistogram"
              :by-area="byArea"
              :group-by-key="'el_tenants'"
              area-specific
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
          <div>
            <h3>
              {{ $t('elGeneralHistogramTitle') }} ({{ $t('totalLabel') }}:
              {{ formatNumber(totalElGeneral / 1000, 0) }} MWh)
            </h3>
            <HistogramChart
              key="elgeneralhistogram"
              :by-area="byArea"
              :group-by-key="'el_general'"
              area-specific
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
          <div>
            <h3>
              {{ $t('elProductionHistogramTitle') }} ({{ $t('totalLabel') }}:
              {{ formatNumber(totalElProduction / 1000, 0) }} MWh)
            </h3>
            <HistogramChart
              :key="'elproductionhistogram'"
              :by-area="byArea"
              :group-by-key="'el_production'"
              area-specific
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
        </div>

        <!-- Building components -->
        <div v-if="selectedKpiTabIndex === 3" :key="`componentsTitle`" class="tab">
          <div>
            <h3>{{ $t('heatingYearTitle') }}</h3>
            <HistogramChart
              key="heatingyearhistogram"
              :by-area="byArea"
              :group-by-key="'heating_year'"
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
          <div>
            <h3>{{ $t('wallsYearTitle') }}</h3>
            <HistogramChart
              key="wallsyearhistogram"
              :by-area="byArea"
              :group-by-key="'walls_year'"
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
          <div>
            <h3>{{ $t('windowsYearTitle') }}</h3>
            <HistogramChart
              key="windowsyearhistogram"
              :by-area="byArea"
              :group-by-key="'windows_year'"
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
          <div>
            <h3>{{ $t('roofYearTitle') }}</h3>
            <HistogramChart
              key="roofyearhistogram"
              :by-area="byArea"
              :group-by-key="'roof_year'"
              :chart-data="chartData"
              class="histogram-chart"
              exportable
            />
          </div>
        </div>

        <!-- Feasibility (Machbarkeit) -->
        <div v-if="selectedKpiTabIndex === 4" :key="`availabilityTitle`" class="tab">
          <div>
            <h3>{{ $t('ewsPieTitle') }}</h3>
            <TagPieChart
              key="ewspiechart"
              :by-area="byArea"
              :group-by-key="'feasibility_ews'"
              :portfolio="portfolio"
              :chart-data="chartData"
              class="pie-chart"
            />
          </div>
          <!-- <div>
          <h3>{{ $t('gwPieTitle') }}</h3>
          <TagPieChart
          :by-area="byArea"
          key="gwpiechart"
          :group-by-key="'feasibility_gw'"
          :portfolio="portfolio"
          :chart-data="chartData"
          class="pie-chart"
          />
        </div> -->
          <div>
            <h3>{{ $t('districtPieTitle') }}</h3>
            <TagPieChart
              key="districtpiechart"
              :by-area="byArea"
              :group-by-key="'availability_district'"
              :portfolio="portfolio"
              :chart-data="chartData"
              class="pie-chart"
            />
          </div>
        </div>
      </template>

      <!-- Energy -->
      <template v-if="portfolio.enabled_features.KPI && kpiMode === 'energy'">
        <template v-for="(indicator, index) in ['ee', 'pe', 'penr', 'per']">
          <div v-if="selectedKpiTabIndex === index" :key="indicator" class="tab">
            <div v-if="indicator === 'pe'">
              <h3>{{ $t('labelTitle') }}</h3>
              <HistogramChart
                key="pelabelhistogram"
                :by-area="byArea"
                :group-by-key="'pe_ratio'"
                :chart-data="chartData"
                class="histogram-chart"
                exportable
              />
            </div>
            <div>
              <h3>
                {{ getIndicatorName(indicator) }} ({{ $t('totalLabel') }}:
                {{ formatNumber(totalKpi(indicator) / 1000, 0) }} {{ getBigUnit(indicator) }})
              </h3>
              <HistogramChart
                :key="indicator + '_histogram'"
                :by-area="byArea"
                :group-by-key="indicator"
                area-specific
                :chart-data="chartData"
                class="histogram-chart"
                exportable
              />
            </div>
            <div>
              <h3>{{ $t('byCarrier') }}</h3>
              <KpiPieChart
                :key="indicator + '_by_carrier'"
                :kpi-type="indicator"
                :group-by-type="'carrier'"
                :portfolio="portfolio"
                :chart-data="chartData"
                class="pie-chart"
              />
            </div>
            <div>
              <h3>{{ $t('byUsage') }}</h3>
              <KpiPieChart
                :key="indicator + '_by_usage'"
                :kpi-type="indicator"
                :group-by-type="'usage'"
                :portfolio="portfolio"
                :chart-data="chartData"
                class="pie-chart"
              />
            </div>
          </div>
        </template>
      </template>

      <!-- Emissions -->
      <template v-if="portfolio.enabled_features.KPI && kpiMode === 'emissions'" class="tab">
        <template v-for="(indicator, index) in ['s12e', 's123e', 's1e', 's2e', 's3e']">
          <div v-if="selectedKpiTabIndex === index" :key="indicator" class="tab">
            <div>
              <h3>
                {{ getIndicatorName(indicator) }} ({{ $t('totalLabel') }}:
                {{ formatNumber(totalKpi(indicator) / 1000, 0) }} {{ getBigUnit(indicator) }})
              </h3>
              <HistogramChart
                :key="indicator + '_histogram'"
                :by-area="byArea"
                :group-by-key="indicator"
                area-specific
                :chart-data="chartData"
                class="histogram-chart"
                exportable
              />
            </div>
            <div>
              <h3>{{ $t('byCarrier') }}</h3>
              <KpiPieChart
                :key="indicator + '_by_carrier'"
                :kpi-type="indicator"
                :group-by-type="'carrier'"
                :portfolio="portfolio"
                :chart-data="chartData"
                class="pie-chart"
              />
            </div>
            <div>
              <h3>{{ $t('byUsage') }}</h3>
              <KpiPieChart
                :key="indicator + '_by_usage'"
                :kpi-type="indicator"
                :group-by-type="'usage'"
                :portfolio="portfolio"
                :chart-data="chartData"
                class="pie-chart"
              />
            </div>
          </div>
        </template>
      </template>

      <!-- Data quality -->
      <template v-if="portfolio.enabled_features.KPI && kpiMode === 'data'">
        <div v-if="selectedKpiTabIndex === 0" :key="`dataQualityTitle`" class="tab">
          <div>
            <h3>{{ $t('dataQualityTitle') }}</h3>
            <DataQualityChart
              key="dataqualitychart"
              :by-area="byArea"
              :chart-data="chartData"
              class="data-quality-chart"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import BubbleChartWrapper from '@/components/portfolio/kpi/charts/BubbleChartWrapper.vue'
import DataQualityChart from '@/components/portfolio/kpi/charts/DataQualityChart.vue'
import TagPieChart from '@/components/portfolio/kpi/charts/TagPieChart.vue'
import KpiPieChart from '@/components/portfolio/kpi/charts/KpiPieChart.vue'
import HistogramChart from '@/components/portfolio/kpi/charts/HistogramChart.vue'
import Menu from '@/components/shared/menu/Menu.vue'
import MenuItem from '@/components/shared/menu/MenuItem.vue'
import KpiBox from '@/components/shared/kpi/KpiBox.vue'

export default {
  components: {
    TagPieChart,
    KpiPieChart,
    DataQualityChart,
    HistogramChart,
    BubbleChartWrapper,
    Menu,
    MenuItem,
    KpiBox,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    layout: {
      type: String,
      default: 'vertical',
    },
  },

  data() {
    return {
      // Helper for translation keys
      kpiTabKeys: {
        overview: {
          0: 'buildings',
          1: 'heating',
          2: 'electricity',
          3: 'buildingComponents',
          4: 'availability',
        },
        energy: {
          0: 'ee',
          1: 'pe',
          2: 'penr',
          3: 'per',
        },
        emissions: {
          0: 's12e',
          1: 's123e',
          2: 's1e',
          3: 's2e',
          4: 's3e',
        },
      },
    }
  },

  computed: {
    ...mapState('portfolio', ['defaultSustainabilityIndicatorIdentifiers']),

    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    // performance vs overview, energy, emissions, data
    kpiMode() {
      return this.$store.state.ui.portfolioKpiMode
    },

    //
    kpiTabs() {
      return this.$store.state.ui.portfolioSelectedKpiTabIndeces
    },

    selectedKpiTabIndex() {
      return this.kpiTabs[this.kpiMode] || 0
    },

    currentKpiTabLabel() {
      return this.$t(`${this.kpiMode}Buttons.${this.kpiTabKeys[this.kpiMode][this.selectedKpiTabIndex]}Button`)
    },

    // count vs area
    kpiDisplayMode() {
      return this.$store.state.ui.portfolioKpiDisplayMode
    },

    byArea() {
      return this.$store.state.ui.portfolioKpiDisplayMode === 'area'
    },

    totalEnergyArea() {
      return this.chartData.reduce((pv, cv) => pv + cv.energy_area, 0)
    },

    totalRh() {
      return this.chartData.reduce((pv, cv) => pv + cv.rh_demand, 0)
    },

    totalHeatEndEnergy() {
      return this.chartData.reduce((pv, cv) => pv + cv.heat_end_energy, 0)
    },

    totalEl() {
      return this.chartData.reduce((pv, cv) => pv + cv.el_demand, 0)
    },

    totalElTenant() {
      return this.chartData.reduce((pv, cv) => pv + cv.el_tenants, 0)
    },

    totalElGeneral() {
      return this.chartData.reduce((pv, cv) => pv + cv.el_general, 0)
    },

    totalElProduction() {
      return this.chartData.reduce((pv, cv) => pv + cv.el_production, 0)
    },
  },

  created() {
    // performance vs overview, energy, emissions, data
    const kpiMode = localStorage.getItem('portfolioKpiMode')
    if (kpiMode) {
      this.$store.dispatch('ui/setPortfolioKpiMode', kpiMode)
    }
    // selected tab index
    // const kpiTabs = JSON.parse(localStorage.getItem('portfolioSelectedKpiTabIndeces'))
    // if (kpiTabs) {
    //   this.$store.dispatch('ui/setPortfolioSelectedKpiTabIndex', kpiTabs)
    // }
    // count vs area
    const kpiDisplayMode = localStorage.getItem('portfolioKpiDisplayMode')
    if (kpiDisplayMode) {
      this.$store.dispatch('ui/setPortfolioKpiDisplayMode', kpiDisplayMode)
    }
  },

  mounted() {
    this.setSelectedKpiTabIndex(this.kpiMode, this.selectedKpiTabIndex)
  },

  methods: {
    //
    getPortfolioKpiTabIndex(group) {
      return this.$store.getters['ui/getPortfolioKpiTabIndex'](group)
    },

    //
    setKpiMode(mode) {
      this.$store.dispatch('ui/setPortfolioKpiMode', mode)
    },

    //
    setSelectedKpiTabIndex(group, index) {
      this.$store.dispatch('ui/setPortfolioSelectedKpiTabIndex', { group, index })
    },

    //
    setKpiDisplayMode(mode) {
      this.$store.dispatch('ui/setPortfolioKpiDisplayMode', mode)
    },

    //
    onBubbleClick(building) {
      this.$router.push({
        name: 'buildingDetails',
        params: { portfolio_id: this.portfolio.id, building_id: building.id },
      })
    },

    //
    totalKpi(kpiType) {
      return this.chartData.reduce((pv, cv) => {
        return pv + (kpiType in cv.kpis ? cv.kpis[kpiType] : 0)
      }, 0)
    },

    //
    getBigUnit(identifier) {
      return this.__getEnhancedSustainabilityIndicator(identifier)?.getBigTargetUnit()
    },

    //
    getIndicatorName(identifier) {
      return this.__getEnhancedSustainabilityIndicator(identifier)?.name
    },
  },
}
</script>

<style lang="scss" scoped>
.c-portfolio-kpis {
  position: relative;
  overflow: hidden;

  & .kpis-container {
    height: calc(100% - 65px);
    overflow-y: auto;
    overflow-x: hidden;

    & .tab {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-l);
      overflow: hidden;
      padding: 20px var(--spacing-s) 160px;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        width: calc(min(100%, 500px));
      }

      &.tab--performance {
        width: 100%;
        min-height: 100%;
        padding: 20px var(--spacing-s);
        overflow-y: auto;

        @media screen and (max-width: 1280px) {
          height: auto;
        }

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          gap: var(--spacing-xl);
          width: 100%;
        }

        & .kpis {
          flex: none;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: var(--spacing-m);
          padding: 0 var(--spacing-s);
        }
      }
    }
  }

  & .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 12px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    height: 65px;

    &.buttons-container--overflow {
      overflow-x: auto;
      overflow-y: hidden;
    }

    & .buttons-container__menu {
      display: none;

      @media screen and (max-width: 2040px) {
        display: initial;
      }
    }

    & .buttons-container__round-buttons {
      display: initial;

      @media screen and (max-width: 2040px) {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss">
.c-portfolio-kpis {
  & .histogram-chart > .chart-container {
    height: 200px;
  }

  & .data-quality-chart > .chart-container {
    height: 300px;
  }

  & .pie-chart > .chart-container {
    height: 200px;
  }

  & h3 {
    text-align: center;
  }
}
</style>
