<i18n>
{
  "de": {
    "pageTitle": "Liegenschaften"
  }
}
</i18n>

<template>
  <SplitLayout :portfolio="portfolio" :ready="portfolioReady" class="p-portfolio-buildings-page">
    <!-- Page title -->
    <template #header>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio" />
    </template>

    <!-- Main Panel: List of Properties -->
    <template #default>
      <!-- Map and KPIs on smaller screens -->
      <PortfolioMapAndKpis
        :portfolio="portfolio"
        :buildings="buildingsForList"
        :chart-data="chartData"
        layout="horizontal"
      />

      <div class="list-controls">
        <div class="list-controls__search">
          <!-- Search bar -->
          <PortfolioSearch :portfolio="portfolio" />
        </div>

        <!-- Filters -->
        <TagFilters :portfolio="portfolio" :tag-groups="portfolioBuildingTags" />
      </div>

      <!-- Properties (Liegenschaften) -->
      <PortfolioList
        :portfolio="portfolio"
        :buildings="buildingsForList"
        :buildings-loading="buildingsForListLoading"
        :search-text="query.search"
      />
    </template>

    <!-- Side Panel: Map and KPI Charts -->
    <template #side-panel>
      <PortfolioMapAndKpis
        :portfolio="portfolio"
        :buildings="buildingsForList"
        :chart-data="chartData"
        class="side-tray"
        layout="vertical"
      />
    </template>
  </SplitLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import SplitLayout from '@/pages/layouts/SplitLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import PortfolioBuildingTagsMixin from '@/pages/vuex-mixins/PortfolioBuildingTagsMixin.vue'
import ChartDataMixin from '@/pages/vuex-mixins/ChartDataMixin.vue'
import PortfolioBuildingsForListMixin from '@/pages/vuex-mixins/PortfolioBuildingsForListMixin.vue'

import PageTitle from '@/components/shared/PageTitle.vue'
import PortfolioSearch from '@/components/portfolio/PortfolioSearch.vue'
import TagFilters from '@/components/portfolio/filters/TagFilters.vue'
import PortfolioList from '@/components/portfolio/PortfolioList.vue'
import PortfolioMapAndKpis from '@/components/portfolio/PortfolioMapAndKpis.vue'

export default {
  name: 'portfolioBuildings',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioBuildingsForListMixin, // Provides: buildingsForList, buildingsForListLoading
    PortfolioBuildingTagsMixin, // Provides: portfolioBuildingTags, portfolioBuildingTagsLoading, portfolioBuildingTagsLoaded
    ChartDataMixin, // Provides: chartData, chartDataLoading, chartDataLoaded
  ],

  components: {
    SplitLayout,
    PortfolioSearch,
    TagFilters,
    PortfolioList,
    PortfolioMapAndKpis,
    PageTitle,
  },

  data() {
    return {
      pageTitle: null,
    }
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
      getCurrentPortfolioId: 'ui/getCurrentPortfolioId',
    }),
  },

  // // Draft: For page title
  // watch: {
  //   portfolio() {
  //     this.onPortfolioUpdated()
  //   },
  // },

  updated() {
    if (String(this.portfolio_id) !== String(this.getCurrentPortfolioId())) {
      this.resetQuery({ portfolioId: this.portfolio_id })
      this.setCurrentPortfolioId(this.portfolio_id)
    }
  },

  methods: {
    ...mapActions({
      setCurrentPortfolioId: 'ui/setCurrentPortfolioId',
      resetQuery: 'query/reset',
    }),

    // // Draft: For page title
    // onPortfolioUpdated() {
    //   this.pageTitle = `${this.$t('pageTitle')} - ${this.portfolio.name}`
    //   this.$emit('updateHead')
    // },
  },

  // // Draft: For page title
  // head() {
  //   return {
  //     title: () => {
  //       if (this.pageTitle) {
  //         return { inner: `${this.pageTitle}` }
  //       }
  //       if (this.portfolio) {
  //         return { inner: `${this.$t('pageTitle')} - ${this.portfolio.name}` }
  //       }
  //     },
  //   }
  // },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss">
.p-portfolio-buildings-page {
  & > .sim-warning {
    padding: var(--spacing-l);
  }

  & .list-controls {
    & .list-controls__search {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: var(--spacing-xs) 0 var(--spacing-s);
    }
  }
}
</style>
